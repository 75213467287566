import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DiamondBiographyGuard implements CanActivate {
  constructor(private router: Router) {}

  //   canActivate(
  //     route: ActivatedRouteSnapshot,
  //     state: RouterStateSnapshot
  //   ): boolean | UrlTree | Promise<boolean | UrlTree> {
  //     let bool = true;
  //     const url = window.location;
  //     if (url.hostname.includes('diamondbiography') && url.pathname !== 'shared')
  //       bool = false;
  //     return bool || this.router.navigate(['page-not-found']);
  //   }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    let bool = true;
    const url = window.location;
    if (url.hostname.includes('diamondbiography') && url.pathname !== 'shared')
      bool = false;
    return bool || this.router.navigate(['page-not-found']);
  }
}
