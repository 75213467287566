import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslateConfigService {
  currentLanguage: any;

  constructor(private translate: TranslateService) {
    this.currentLanguage = this.translate.currentLang;
    if(!this.currentLanguage){      
      if(!!localStorage.getItem('lang')){
        this.currentLanguage = localStorage.getItem('lang') 
      }else{
        this.currentLanguage = this.translate.getBrowserLang();
      }
    }
  }

  public  getDefaultLanguage() {
    // this.translate.currentLang
    console.log(localStorage.getItem('lang'));
    let lang: any = localStorage.getItem('lang') || this.translate.getBrowserLang();
    this.currentLanguage = lang
    this.translate.setDefaultLang(lang);
    return lang;
    // if (this.currentLang) {
    //   this.translate.setDefaultLang(this.currentLang);
    // } else {
    //   localStorage.setItem('lang', this.translate.getBrowserLang()!);
    //   this.currentLang = this.translate.getBrowserLang();
    //   this.translate.setDefaultLang(this.currentLang);
    // }
    //  return this.currentLang;
  }

  public setLanguage(setLang: string) {
    this.translate.use(setLang);
    this.translate.setDefaultLang(setLang);
    localStorage.setItem('lang', setLang);
  }

  public getCurrentLang() {
    let lang = localStorage.getItem('lang') || this.translate.getBrowserLang();
    console.log(lang)
    return lang;
  }
}
