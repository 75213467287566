import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { HeaderFooterService } from './service/headerFooter/header-footer.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public storage: Storage,
    public headerService: HeaderFooterService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log(error);
        if (error.status === 401) {
          this.storage.remove('userID');
          this.storage.remove('guestUser');
          this.storage.remove('loggedInUser');
          this.storage.remove('userData');
          this.storage.remove('pincode');
          // this.storage.remove('talkBriteAccessToken');
          this.storage.remove('searchData');
          this.storage.remove('deeplink-stoneName');
          this.storage.remove('deeplink-location');
          this.storage.remove('distributorEmailSended');
          // Log out user and navigate to login page
          this.headerService.logoutUser()
          this.router
            .navigate(['/login-with-sign-up'])
            .then(() => {
              // Optional: you can do something after navigation if needed
            })
            .catch(err => {
              console.error('Navigation error:', err);
            });
          return null; // or of(null);
        }
        return throwError(error); // Rethrow the error for other cases
      })
    );
  }
}
