import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Storage } from '@ionic/storage';
import { LOGGEDINService } from 'src/app/service/observable/user/loggedin.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private loggedInUser: any;

  constructor(private router: Router, private storage: Storage) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    this.loggedInUser = await this.storage.get('loggedInUser');
    console.log(this.loggedInUser);

    const isLoginWithSignUpRoute = state.url === '/login-with-sign-up';
    const searchId = route.queryParams['searchId'];

    if (!!searchId) {
      return true;
    }

    if (this.loggedInUser) {
      if (isLoginWithSignUpRoute) {
        // User is already logged in and trying to access login-with-sign-up, redirect to home
        this.router.navigate(['/diamond-search']);
        return false;
      } else {
        // User is logged in and trying to access other routes
        return true;
      }
    } else {
      if (isLoginWithSignUpRoute) {
        // User is not logged in but accessing login-with-sign-up, allow it
        return true;
      } else {
        // User is not logged in and trying to access protected route, redirect to login-with-sign-up
        this.router.navigate(['/login-with-sign-up']);
        return false;
      }
    }
  }
}
