import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { CloudinaryModule } from '@cloudinary/angular-5.x';
import { Device } from '@ionic-native/device/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { File } from '@ionic-native/file/ngx';
import {
  FileTransfer,
  FileTransferObject,
} from '@ionic-native/file-transfer/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';
import { Cloudinary } from 'cloudinary-core/cloudinary-core-shrinkwrap';
import { CartActionsComponent } from '../app/components/cart/cart-actions/cart-actions.component';
import { environment } from '../environments/environment';
//import { DiamondSearchPageModule } from './pages/diamond-search/diamond-search.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderFooterComponentsModule } from './components/header-footer.components.module';
import { IonIntlTelInputModule } from './components/ion-intl-tel-input/ion-intl-tel-input.module';
import { CompanyService } from './service/company/company.service';
import { ConfigServiceService } from './service/config-service.service';
import { HeaderFooterService } from './service/headerFooter/header-footer.service';
import { ThemeService } from './service/theme.service';
//import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx';
// import { DocumentViewer } from '@ionic-native/document-viewer';
//import * as Hammer from 'hammerjs';

import {
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { CartAddedPopupPageModule } from './pages/cart-added-popup/cart-added-popup.module';
import { AnalyticsService } from './service/analytics.service';
import { ShowDNAComponent } from './components/DNA/show-dna.component';
import { BookAppointmentModule } from './components/book-appointment.module';
// import { SortCodesPipe } from './pipes/sort-code/sort-codes.pipe';
import { AppVersion } from '@ionic-native/app-version/ngx';
// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     swipe: { direction: Hammer.DIRECTION_ALL },
//   };
// }
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Globalization } from '@ionic-native/globalization/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export const cloudinaryLib = {
  Cloudinary: Cloudinary,
};

const cloudConfig = {
  cloud_name: environment.companyDetails.config.cludinaryCloud,
};

@NgModule({
  declarations: [AppComponent, CartActionsComponent, ShowDNAComponent],
  imports: [
    IonIntlTelInputModule,
    HeaderFooterComponentsModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    HttpClientModule,
    IonicModule.forRoot(),
    CloudinaryModule.forRoot(cloudinaryLib, cloudConfig),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    CartAddedPopupPageModule,
    BookAppointmentModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ConfigServiceService,
    AnalyticsService,
    CompanyService,
    PreviewAnyFile,
    File,
    FileTransfer,
    HeaderFooterService,
    InAppBrowser,
    Device,
    ThemeService,
    SocialSharing,
    Meta,
    AppVersion,
    Globalization,
    Clipboard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
