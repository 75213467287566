import { Injectable } from '@angular/core';
import {
  NoPreloading,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';

import { Observable } from 'rxjs';
import { CompanyService } from './service/company/company.service';
import { DiamondBiographyGuard } from './shared/guard/diamondBiography.guard';

interface DeeplinkMatch {
  $stoneName: string;
  $refKgCompanyId: number;
}

@Injectable({
  providedIn: 'root',
})
export class DefauultRouteService implements CanActivate {
  branchData: DeeplinkMatch;

  constructor(
    private platform: Platform,
    private router: Router,
    public storage: Storage,
    private _companyService: CompanyService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      let res: any = await this._companyService.defaultHomePage();

      if (this.platform.is('cordova')) {
        const branchIo = window['Branch'];
        if (!this.branchData) {
          this.branchData = await branchIo.initSession();
        }
        console.log(this.branchData);
        try {
          let user = await this.storage.get('loggedInUser');
          if (
            !user &&
            !!this.branchData &&
            this.branchData?.$stoneName !== undefined &&
            this.branchData?.$refKgCompanyId !== undefined
          ) {
            this.storage.set('deeplink-stoneName', this.branchData.$stoneName);
            this.storage.set(
              'deeplink-location',
              this.branchData.$refKgCompanyId
            );
            this.router.navigateByUrl(res);
            resolve(false);
          } else if (
            user &&
            !!this.branchData &&
            this.branchData?.$stoneName !== undefined &&
            this.branchData?.$refKgCompanyId !== undefined
          ) {
            this.router.navigate([
              '/products/' +
                this.branchData.$stoneName +
                '/' +
                this.branchData.$stoneName +
                '/' +
                this.branchData.$refKgCompanyId,
            ]);
            resolve(false);
          } else {
            this.router.navigateByUrl(res);
            resolve(false);
          }
          // })
        } catch (err) {
          console.error(err);
        }
      } else {
        const url = window.location;
        if (
          url.hostname.includes('diamondbiography') &&
          url.pathname !== 'shared'
        )
          this.router.navigate(['page-not-found']);
        else this.router.navigateByUrl(res);
        resolve(false);
      }
    });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/authGuard.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'home/:id',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'collections/:id',
    pathMatch: 'prefix',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/collections/collections.module').then(
        m => m.CollectionsPageModule
      ),
  },
  {
    path: 'collections/:id/:category',
    pathMatch: 'prefix',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/collections/collections.module').then(
        m => m.CollectionsPageModule
      ),
  },
  {
    path: 'my-addresses',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/my-addresses/my-addresses.module').then(
        m => m.MyAddressesPageModule
      ),
  },
  {
    path: 'add-new-address',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/add-new-address/add-new-address.module').then(
        m => m.AddNewAddressPageModule
      ),
  },
  {
    path: 'login',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'products/:id/:title/:location',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        m => m.ProductsPageModule
      ),
  },
  {
    path: 'products/:id/:title',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        m => m.ProductsPageModule
      ),
  },
  {
    path: 'products/:id',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        m => m.ProductsPageModule
      ),
  },
  {
    path: 'reportNo/:reportNo',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/products/products.module').then(
        m => m.ProductsPageModule
      ),
  },
  {
    path: 'orders/:status',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/orders/orders.module').then(m => m.OrdersPageModule),
  },
  {
    path: 'my-favorite',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/my-favorite/my-favorite.module').then(
        m => m.MyFavoritePageModule
      ),
  },
  {
    path: 'setting',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/setting/setting.module').then(m => m.SettingPageModule),
  },
  {
    path: 'profile-update',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/profile-update/profile-update.module').then(
        m => m.ProfileUpdatePageModule
      ),
  },
  {
    path: 'manage-orders',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/manage-orders/manage-orders.module').then(
        m => m.ManageOrdersPageModule
      ),
  },
  {
    path: 'new-order',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/new-order/new-order.module').then(
        m => m.NewOrderPageModule
      ),
  },
  {
    path: 'manage-order-details/:id',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/manage-order-details/manage-order-details.module').then(
        m => m.ManageOrderDetailsPageModule
      ),
  },
  {
    path: 'view-order-details/:publicId',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/manage-order-details/manage-order-details.module').then(
        m => m.ManageOrderDetailsPageModule
      ),
  },
  {
    path: 'about-us',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/about-us/about-us.module').then(m => m.AboutUsPageModule),
  },
  {
    path: 'page-contents',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/page-contents/page-contents.module').then(
        m => m.PageContentsPageModule
      ),
  },
  {
    path: 'search',
    canActivate: [AuthGuard, DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/search/search.module').then(m => m.SearchPageModule),
  },
  {
    path: 'schemes',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/schemes/schemes.module').then(m => m.SchemesPageModule),
  },
  {
    path: 'dispatchs',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/dispatchs/dispatchs.module').then(
        m => m.DispatchsPageModule
      ),
  },
  {
    path: 'view-dispatch/:id',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/view-dispatch/view-dispatch.module').then(
        m => m.ViewDispatchPageModule
      ),
  },
  {
    path: 'notifications',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        m => m.NotificationsPageModule
      ),
  },
  {
    path: 'order-freshness',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/order-freshness/order-freshness.module').then(
        m => m.OrderFreshnessPageModule
      ),
  },
  {
    path: 'login-with-sign-up',
    canActivate: [AuthGuard, DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/login-with-sign-up/login-with-sign-up.module').then(
        m => m.LoginWithSignUpPageModule
      ),
  },
  {
    path: 'change-password',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        m => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'product-form',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/product-form/product-form.module').then(
        m => m.ProductFormPageModule
      ),
  },
  {
    path: 'show-cart/:id',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/show-cart/show-cart.module').then(
        m => m.ShowCartPageModule
      ),
  },
  {
    path: 'saved-carts',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/saved-carts/saved-carts.module').then(
        m => m.SavedCartsPageModule
      ),
  },
  {
    path: 'saved-searches',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/saved-searches/saved-searches.module').then(
        m => m.SavedSearchesPageModule
      ),
  },
  {
    path: 'my-cart',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/my-cart/my-cart.module').then(m => m.MyCartPageModule),
  },
  {
    path: 'diamond-search',
    canActivate: [AuthGuard, DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/diamond-search/diamond-search.module').then(
        m => m.DiamondSearchPageModule
      ),
  },
  {
    path: 'ngx-form',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/ngx-form/ngx-form.module').then(m => m.NgxFormPageModule),
  },
  {
    path: 'guest-login',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/guest-login/guest-login.module').then(
        m => m.GuestLoginPageModule
      ),
  },
  {
    path: 'pd-parameters',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/pd-parameters/pd-parameters.module').then(
        m => m.PdParametersPageModule
      ),
  },
  {
    path: 'my-customers',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/my-customers/my-customers.module').then(
        m => m.MyCustomersPageModule
      ),
  },
  {
    path: 'general-info',
    loadChildren: () =>
      import('./pages/diamond/profile/general-info/general-info.module').then(
        m => m.GeneralInfoPageModule
      ),
  },
  {
    path: 'add-customer',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/add-customer/add-customer.module').then(
        m => m.AddCustomerPageModule
      ),
  },
  {
    path: 'my-login',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/my-login/my-login.module').then(m => m.MyLoginPageModule),
  },
  {
    path: 'quick-buy',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/quick-buy/quick-buy.module').then(
        m => m.QuickBuyPageModule
      ),
  },
  // { path: "", canActivate: [DefauultRouteService], loadChildren: () => import("./pages/select-suppliers/select-suppliers.module").then((m) => m.SelectSuppliersPageModule) }
  {
    path: '',
    canActivate: [DefauultRouteService],
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule),
  },
  {
    path: 'track-order',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/track-order/track-order.module').then(
        m => m.TrackOrderPageModule
      ),
  },
  {
    path: 'cart-added-popup',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/cart-added-popup/cart-added-popup.module').then(
        m => m.CartAddedPopupPageModule
      ),
  },
  {
    path: 'maintenance',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/static/static.module').then(m => m.StaticPageModule),
  },
  // {
  //   path: 'accessories',
  //   loadChildren: () =>
  //     import('./pages/accessories/accessories.module').then(
  //       m => m.AccessoriesPageModule
  //     ),
  // },
  {
    path: 'delete-account',
    canActivate: [DiamondBiographyGuard],
    loadChildren: () =>
      import('./pages/delete-account-form/delete-account-form.module').then(
        m => m.DeleteAccountFormPageModule
      ),
  },
  // {
  //   path: 'shared',
  //   loadChildren: () =>
  //     import('./pages/page-not-found/page-not-found.module').then(
  //       m => m.PageNotFoundPageModule
  //     ),
  // },
  {
    path: 'shared/:uuid',
    loadChildren: () =>
      import('./pages/custom-inspect-view/custom-inspect-view.module').then(
        m => m.CustomInspectViewPageModule
      ),
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundPageModule
      ),
  },
  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        m => m.PageNotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
